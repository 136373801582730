.auth-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 52px;

    width: 502px;
    margin: auto;
    margin-top: 100px;
}

.auth-form__data-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 52px;
}

.auth-form__buttons {
    display: flex;
    gap: 20px;
}
  
.form-wrapper {
    padding-top: 28px;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 28px;
}

.error-message {
    position: absolute;
    margin: 0;
    padding: 0;
    color:  var(--danger-red-2);
    font-size: 12px;
}

.input-container {
    position: relative;
}

.password-field-container {
    position: relative;
    display: flex;
    align-items: center;
}

.show-hide-icon {
    box-sizing: border-box;
    position: absolute;
    left: 93%;
    bottom: 1px;

    padding: 8px 10px 6px;
}

.show-hide-icon:hover {
    cursor: pointer;
    color: var(--primary-color);
}

.link {
    text-decoration: none;
}