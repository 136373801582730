.route-container {
    display: flex;
    gap: 31px;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: 40px;
    background-color: var(--light-gray);
    padding: 40px 40px 40px 20px;
    width: 672px;
}

.drag-icon {
    width: 32px;
    height: 32px;
    cursor: grab;
}

.route-content {
    display: flex;
    flex-direction: column;
    gap: 28px;
    flex: 1 0 0;
}

.text-area {
    padding: 8px 12px;
    resize: none;

    width: 100%;
    height: 172px;
    border-radius: 4px;
    border: 1px solid var(--gray);
    background-color: #FFF;

    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.text-area:focus, .input-field:focus {
    outline: none;
    border-color: var(--black);
}

.text-area::placeholder, .input-field::placeholder {
    color: var(--gray);
    opacity: 1;
}

.drag-n-drop-area {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.drag-n-drop-description {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.drag-n-drop-text {
    color: var(--gray);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}