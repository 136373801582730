.userpic-stub {
    display: block;
    box-sizing: border-box;
    border: 1px solid var(--primary-color);
    border-radius: 50%;
    text-align: center;
}

.userpic-small {
    width: 18px;
    height: 18px;
}

.userpic-medium {
    width: 40px;
    height: 40px;
}

.userpic-large {
    width: 120px;
    height: 120px;
}

.userpic-stub__icon {
    width: 95%;
    height: 95%;
    color: var(--primary-color);
}

.userpic {
    display: block;
    box-sizing: border-box;
    border-radius: 50%;
}