* {
    box-sizing: border-box;
}

.starting-page__layout {
    display: flex;
    flex-direction: column;
}

.welcome-page__content {
    width: 22%;
    margin: 140px auto 354px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 52px;

    text-align: center;
}

.content__picture {
    width: 127px;
    height: 127px;
}

.content__text {
    color: var(--primary-color);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.content__buttons {
    display: flex;
    gap: 20px;
}