.placeholder {
    border: 2px solid var(--light-gray);
    border-radius: 20px;

    width: 100%;

    display: flex;
    justify-content: center;

    padding: 25px 30px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: normal;
    color: var(--primary-color);

    margin-bottom: 50px;
}