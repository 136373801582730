@font-face {
    font-family: 'Jura';
    src: url('assets/fonts/Jura.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), 
        url('assets/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), 
        url('assets/fonts/Inter-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter'), 
        url('assets/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}