.second-step-container {
    display: flex;
    flex-direction: column;
    gap: 52px;
}

.trip-fields {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.buttons {
    display: flex;
    gap: 14px;
}