.trip-card {
 display: flex;
 flex-direction: column;
 gap: 20px;

 width: 439px;
 padding: 7px;
}

.trip-card__header {
    display: flex;
    gap: 40px;
}

.trip-card__edit-logo {
}

.trip-card__edit-button {
    padding: 0;
    border: none;
    background-color: transparent;
}

.trip-card__edit-button:hover {
    cursor: pointer;
}

.trip-card__info {
    display: flex;
    gap: 5px;

    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
    color: var(--dark-gray);
}

p {
    margin: 0;
}