.third-step-container {
    width: 48%;
}

.third-step__tags-discription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    margin-bottom: 26px;
}

.tags-discription__text {
    color: var(--gray);
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.third-step__tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px;
}

.third-step__buttons {
    display: flex;
    gap: 14px;
}