.location-list-container {
    width: 440px;
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: flex-start;

    padding: 32px;

    border-radius: 20px;
    background: var(--light-gray);

    position: fixed;
    left: 961px;
}

.location {
    display: flex;
    flex-direction: column;
    gap: 3px;

    cursor: grab;
}

.location__header {
    display: flex;
    gap: 13px;
}

.location__name {
    color: var(--black);
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.drag-icon {
    width: 16px;
    height: 16px;
}

.location-list__buttons {
    display: flex;
    gap: 14px;
}

.location-list-droppable {
    display: flex;
    flex-direction: column;
    gap: 25px;
}
