.button {
    display: flex;
    height: 34px;
    align-items: center;
    padding: 8px 12px;
    gap: 8px;

    border-radius: 4px;

    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.button:hover {
    cursor: pointer;
}

.primary-button {
    border: none;
    background-color: var(--primary-color);
    color: #FFF;
}

.secondary-button {
    border: none;
    background-color: rgba(255, 79, 24, 0.10);
    color: var(--primary-color);
}

.danger-button {
    border: 1px solid var(--danger-red-2);
    background-color: var(--danger-red-1);
    color: var(--danger-red-2);
}

.plain-button {
    border: none;
    background-color: #FFF;
    color: var(--primary-color);
}

[aria-disabled="true"] {
    opacity: 0.7;
}

[aria-disabled="true"]:focus {
    cursor: default;
}

