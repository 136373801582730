.page-header {
    position: fixed;
    left: 0px;
    top: 0px;

    width: 100%;
    height: 102px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: var(--background-color);
}

.page-header__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 40px 36px;
}

.page-header__friends-group {
    display: flex;
    align-items: center;
    gap: 4px;
}

.friend {
    width: 24px;
    height: 24px;
}

.one {
    margin-left: -2px;
}

.two {
    margin-left: -2px;
    margin-right: -2px;
}

.three {
    margin-right: -2px;
}

.page-header__logo-group {
    
}

.logo {
    width: 85px;
    height: 34px;
}

.page-header__buttons {
    display: flex;
    gap: 14px;
}

.page-header__text {
    margin-left: 72px;
    color: var(--primary-color);
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.link {
    text-decoration: none;
}