@import 'assets/fonts/fonts.css';
@import 'styles/variables.css';

@import 'components/Button/index.css';
@import 'components/Header/index.css';
@import 'components/Input/index.css';
@import 'components/TripCard/index.css';
@import 'components/PageHeader/index.css';
@import 'components/TopTripsCard/index.css';
@import 'components/Userpic/index.css';
@import 'components/Placeholders/TopTripFetchFailPlaceholder/index.css';
@import 'components/Placeholders/TopTripsEmptyPlaceholder/index.css';
@import 'components/Footer/index.css';
@import 'components/Stepper/index.css';
@import 'components/CreateLocation/index.css';
@import 'components/CreateTripRoute/index.css';
@import 'components/ImgUploader/index.css';
@import 'components/LocationList/index.css';

@import 'views/SignUpForm/index.css';
@import 'views/AuthForm/index.css';
@import 'views/Toggler/index.css';
@import 'views/TripList/index.css';
@import 'views/UserProfileCard/index.css';
@import 'views/TripCreateSecondStep/index.css';
@import 'views/TripInit/index.css';
@import 'views/TripCreateFirstStep/index.css';
@import 'views/TripCreateThirdStep/index.css';

@import 'pages/StartingPage/index.css';
@import 'pages/AuthPage/index.css';
@import 'pages/RegisterPage/index.css';
@import 'pages/NotFoundPage/index.css';
@import 'pages/ProfilePage/index.css';
@import 'pages/WelcomePage/index.css';
@import 'pages/TripCreatePage/index.css';


body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: var(--background-color);
  }
  
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
  padding-top: 102px;
}